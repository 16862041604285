import HttpActions from '../http/actions';
import { URLS } from '../../utils';


export const ConstantesActions = {    
    CARREGAR_CHECKPOINTS_PENDENTES: 'CARREGAR_CHECKPOINTS_PENDENTES',
    SELECIONAR_CHECKPOINT: 'SELECIONAR_CHECKPOINT',
    MUDAR_ETAPA: 'MUDAR_ETAPA',
    
    ETAPA: {
        SELECIONAR_CHECKPOINT: 'SELECIONAR_CHECKPOINT',
        CHECKPOINT_SELECIONADO: 'CHECKPOINT_SELECIONADO',
        CHECKPOINT_REALIZADO: 'CHECKPOINT_REALIZADO',
        CHECKPOINT_SELECIONADO_SEM_MODAL: 'CHECKPOINT_SELECIONADO_SEM_MODAL'
    }
}

const Actions = {
    
    getCheckpointsPendentes: () => HttpActions.get({ URL: URLS.SERVER.CHECKPOINT.CHECKPOINTS_PENDENTES, retorno: Actions.carregarCheckpointsPendentes }),
    carregarCheckpointsPendentes: (checkpoints) => ({ type: ConstantesActions.CARREGAR_CHECKPOINTS_PENDENTES, payload: { checkpoints } }),
    selecionarCheckPoint: (checkpointSelecionado) => ({ type: ConstantesActions.SELECIONAR_CHECKPOINT, payload: { checkpointSelecionado, etapa: ConstantesActions.ETAPA.CHECKPOINT_SELECIONADO } }),
    selecionarCheckPointSemEtapa: (checkpointSelecionado) => ({ type: ConstantesActions.SELECIONAR_CHECKPOINT, payload: { checkpointSelecionado, etapa: ConstantesActions.ETAPA.CHECKPOINT_SELECIONADO_SEM_MODAL } }),
    
    showEtapaSelecionarCheckpoint: () => Actions.__mudarEtapa(ConstantesActions.ETAPA.SELECIONAR_CHECKPOINT),
    showEtapaCheckpointRealizado: () => Actions.__mudarEtapa(ConstantesActions.ETAPA.CHECKPOINT_REALIZADO),
    __mudarEtapa: (etapa) => ({ type: ConstantesActions.MUDAR_ETAPA, payload: { etapa } }),
    
    postAtualizarCheckpointAluno: (params) => HttpActions.post({ URL: URLS.SERVER.CHECKPOINT.ATUALIZAR_CHECKPOINT_ALUNO, params, retorno: Actions.showEtapaCheckpointRealizado }), 
};


export default Actions;