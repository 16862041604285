import React from "react";
import { LayoutContentWrapper } from "./layout-content-wrapper.style";


export default props => {	
	return (
  	<LayoutContentWrapper className='isoLayoutContentWrapper'>			
    	{props.children}
  	</LayoutContentWrapper>
)};
