
import { Axios } from '../http/rest'

export default class RestAPI {

    static login(email, password){
        return Axios.post('/login', { email, password })
    }

    static loginGoogle(email) {
        return Axios.post('/login-google', { email })
    }

    static forgotPassword(email, password) {
        return Axios.post('/auth/create', { email, password })
    }

    static findInfoToken(token) {
        return Axios.get(`/auth/find/${token}`)
    }

    static resetPassword(data) {
        return Axios.post('/auth/reset', { ...data })
    }
}

