import actions from "./actions";

const initState = { idToken: null, loading: false };

export default function authReducer(state = initState, action) {
  	switch (action.type) {

		// LOGIN		
        case actions.LOGIN_REQUEST:		
            return { ...initState, loading: true };
        case actions.LOGIN_GOOGLE_REQUEST:
			return { ...initState, loading: true };
				    
		case actions.LOGIN_SUCCESS:			
			return { usuario: action.usuario, loading: false };
		case actions.LOGIN_ERROR:
			return { ...initState, loading: false, ...action.payload };
			
		// LOGOUT
		case actions.LOGOUT:
			return { ...initState };
			
		// FORGOT_PASSWORD
		case actions.FORGOT_PASSWORD_REQUEST:
			return { ...initState, loading: true};
		case actions.FORGOT_PASSWORD_SUCCESS:
			return { ...initState, tipoMensagem: 'success', mensagem: action.mensagem  };
		case actions.FORGOT_PASSWORD_ERROR:
			return { ...initState, loading: false, tipoMensagem: 'error', mensagem: action.mensagem };

		// RESET_PASSWORD
		case actions.SET_INFO_TOKEN:
			return { ...state, dataResetPassword: action.dataResetPassword };
		case actions.SET_INFO_TOKEN_ERROR:
			return { ...state, erroToken: true, tipoMensagem: 'error', mensagem: action.mensagem  };			
		case actions.RESET_PASSWORD_REQUEST:
			return { ...initState, loading: true };
		case actions.RESET_PASSWORD_SUCCESS:
			return { ...initState, tipoMensagem: 'success', mensagem: 'Senha redefinida!', redirectLogin: true };
		case actions.RESET_PASSWORD_ERROR:
			return { ...initState, loading: false, tipoMensagem: 'error', mensagem: action.mensagem };
		// CLEAR
		case actions.CLEAR:
			return { ...initState, idToken: state.idToken, usuario: state.usuario};
		
		default:
			return state;
	}
}
