import { ConstantesActions } from "./actions";

const initState = { };

export default function checkpointsPendentesReducer(state = initState, action) {
    

    switch (action.type) {
        
        case ConstantesActions.CARREGAR_CHECKPOINTS_PENDENTES:
        case ConstantesActions.SELECIONAR_CHECKPOINT:
        case ConstantesActions.MARCAR_CHECKPOINT_ALUNO_FINALIZADO:
        case ConstantesActions.SALVAR_CHECKPOINT_ALUNO:        
        case ConstantesActions.MUDAR_ETAPA:
            return { ...state, ...action.payload }
            
        default:
            return state;
  }  
}
