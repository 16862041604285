import React, { Fragment } from 'react';
import { Route } from "react-router-dom";

import CheckPointAluno from './checkpoint-aluno/'
import CheckPointsPendentes from './checkpoints-pendentes';



const Router = (props) => {    
    return (
        <Fragment>
            <Route exact path="/" component={CheckPointAluno} />
            <Route exact path="/checkpoint-aluno" component={CheckPointAluno} />
            <Route exact path="/checkpoints-pendentes" component={CheckPointsPendentes} />
            
            <Route path='/redirect' component={({...props}) => { 
                window.location.replace(props.link);
                return null;
            }}/>      
        </Fragment>        
    );
};


export default Router;


