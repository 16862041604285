import App from './app/reducer';
import Auth from './auth/reducer';
import CheckPointAluno from './checkpoint-aluno/reducer';
import CheckPointsPendentes from './checkpoints-pendentes/reducer';

export default {
	// APP
	App, 
    Auth, 
    CheckPointAluno,
    CheckPointsPendentes

};
