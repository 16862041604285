import React from 'react';
import { Modal, Icon, Spin } from 'antd';
import { connect } from 'react-redux';


const DefaultModal = (props) =>

    <Modal
        maskClosable={false}
        closable={!props.loading}
        confirmLoading={props.loading}
        cancelButtonProps={{ disabled: props.loading }}
        {...props}
    >
        <Spin spinning={props.loading} indicator={iconLoading}>
            {props.children}
        </Spin>
    </Modal>            
    

const iconLoading = <Icon type="loading" style={{ fontSize: 32 }} spin />;

export default connect(state => ({ ...state.App }))(DefaultModal);