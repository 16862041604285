import axios from 'axios'
import { URLS } from '../../utils'



export const Axios = axios.create({
    baseURL: URLS.SERVER.BASE_URL
});


export default Axios ;