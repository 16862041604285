import { all, takeEvery, fork, put } from 'redux-saga/effects';
import AuthActions from './actions';
import RestAPI from './rest'
import Axios from '../http/rest';

export function* loginRequest() {

	yield takeEvery(AuthActions.LOGIN_REQUEST, function* (action) {
		const { email, password } = yield action.payload;

		try{
			const response = yield RestAPI.login(email, password)						
			yield put({ type: AuthActions.LOGIN_SUCCESS, ...response.data });
			
		}catch(erro){
			let mensagem = '';
			if (erro.response.status === 401)
				mensagem = 'E-mail ou Senha inválidos!';
			else
				mensagem = 'Ocorreu um erro, tente novamente mais tarde.';
			
			yield put(AuthActions.loginErro(mensagem));
		}
	});
}


export function* loginGoogleRequest() {

    yield takeEvery(AuthActions.LOGIN_GOOGLE_REQUEST, function* (action) {
        const { email } = yield action.payload;

        try {
            const response = yield RestAPI.loginGoogle(email);
            yield put({ type: AuthActions.LOGIN_SUCCESS, ...response.data });
        } catch (erro) {
            let mensagem = '';
            if (erro.response.status === 401)
                mensagem = 'E-mail ou Senha inválidos!';
            else
                mensagem = 'Ocorreu um erro, tente novamente mais tarde.';

            yield put({ type: AuthActions.LOGIN_ERROR, mensagem: mensagem });
        }
    });
}

export function* loginSuccess() {
  	yield takeEvery(AuthActions.LOGIN_SUCCESS, function*(action) {			
		yield Axios.defaults.headers.common['Authorization'] = `Bearer ${action.usuario.token}`;		
		yield localStorage.setItem('usuario', JSON.stringify(action.usuario));		
  	});
}

export function* checkAuthorization() {
	yield takeEvery(AuthActions.CHECK_AUTHORIZATION, function* () {		
		const stringUsuario = yield localStorage.getItem('usuario');		
		const usuario = yield stringUsuario ? JSON.parse(stringUsuario) : null;
		if(usuario && usuario.token){		
			yield put({ type: AuthActions.LOGIN_SUCCESS, usuario });
		}
	});
}

export function* logout() {
  	yield takeEvery(AuthActions.LOGOUT, function*() {
		yield Axios.defaults.headers.common['Authorization'] = null
		yield localStorage.removeItem('usuario');   		
  	});
}

export function* forgotPasswordRequest() {

	yield takeEvery(AuthActions.FORGOT_PASSWORD_REQUEST, function* (action) {
		const { email } = yield action.payload;

		try {
			const response = yield RestAPI.forgotPassword(email)
			yield put({ type: AuthActions.FORGOT_PASSWORD_SUCCESS, mensagem: response.data.mensagem });
		} catch (erro) {
			const mensagem = (erro.response.data && erro.response.data.mensagem ) ? erro.response.data.mensagem : 'Ocorreu um erro, tente novamente mais tarde.';			
			yield put({ type: AuthActions.FORGOT_PASSWORD_ERROR, mensagem: mensagem });
		}
	});
}


export function* findInfoUserByToken() {

	yield takeEvery(AuthActions.FIND_INFO_USER_BY_TOKEN, function* (action) {
		const { token } = yield action.payload;

		try {
			const response = yield RestAPI.findInfoToken(token)
			yield put({ type: AuthActions.SET_INFO_TOKEN, dataResetPassword: response.data });
		} catch (erro) {
			const mensagem = (erro.response.data) ? erro.response.data.mensagem : 'Ocorreu um erro, tente novamente mais tarde.'
			yield put({ type: AuthActions.SET_INFO_TOKEN_ERROR, mensagem: mensagem });
		}
	});
}

export function* resetPassword() {

	yield takeEvery(AuthActions.RESET_PASSWORD_REQUEST, function* (action) {
		
		try {
			const response = yield RestAPI.resetPassword(action.payload)
			yield put({ type: AuthActions.RESET_PASSWORD_SUCCESS, payload: response.data });
		} catch (erro) {
			const mensagem = (erro.response.data) ? erro.response.data.mensagem : 'Ocorreu um erro, tente novamente mais tarde.'
			yield put({ type: AuthActions.RESET_PASSWORD_ERROR, mensagem: mensagem });
		}
	});
}


export default function* rootSaga() {
	yield all([
		fork(checkAuthorization),
        fork(loginRequest),
        fork(loginGoogleRequest),
		fork(loginSuccess),    
		fork(logout),
		fork(forgotPasswordRequest),
		fork(findInfoUserByToken),
		fork(resetPassword)
  	]);
}
