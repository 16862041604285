const options = [
  	{

        key: 'checkpoint-aluno',
        label: 'Checkpoint',
        leftIcon: 'solution',
        leftIconTheme: 'outlined',
    },
    {

        key: 'checkpoints-pendentes',
        label: 'Checkpoints Pendentes',
        leftIcon: 'alert',
        leftIconTheme: 'outlined',
    }

        /*
        {
            key: 'mod_pessoal',
            label: 'Pessoal',
            leftIcon: 'team',
            leftIconTheme: 'outlined',
            children: [
                { key: 'pessoal/membros', label: 'Membros' },
                { key: 'pessoal/entidades', label: 'Entidades Espirituais' },
                { key: 'pessoal/batizados', label: 'Batizados' },
                { key: 'pessoal/casamentos', label: 'Casamentos' },
                { key: 'pessoal/organograma', label: 'Organograma' },
                { key: 'pessoal/ordens-diretoria', label: 'Ordens da Diretoria Espiritual' },
                { key: 'pessoal/arquivos', label: 'Arquivos' },
                { key: 'pessoal/envios-email', label: 'Envios de E-mail' },
            ]
        },
        */
	

];




export default options;
