
import Profile from '../config/profile/';


export default {

    FRONT: {
        BASE_URL: Profile.BASE_URL_FRONT,            
    },

    SERVER: {
        BASE_URL: Profile.BASE_URL_API,

        CHECKPOINT: {
            TURMAS_DO_PROFESSOR: '/checkpoint/turmas-do-professor',
            ALUNOS_DA_TURMA: '/checkpoint/alunos-da-turma',
            SALVAR_CHECKPOINT_ALUNO: 'checkpoint/salvar-checkpoint-aluno',
            CHECKPOINTS_PENDENTES: 'checkpoint/checkpoints-pendentes',
            ATUALIZAR_CHECKPOINT_ALUNO: 'checkpoint/atualizar-checkpoint-aluno',
        }
    },

        getURLKey: () => window.location.pathname.split('/')[1],
}