import React, {Component} from 'react';
import { Form, Icon, Input, Button, Checkbox, Card, Alert, Spin } from 'antd';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';

import './styles.css';

import Validacao from '../../utils/validacao';
import AuthActions from '../../redux/auth/actions';


class Login extends Component {
    
    
    handleSubmit = e => {
        e.preventDefault();
        const {form, login} = this.props;

        form.validateFields((err, values) => {
            if (!err)
                login(values);
        });

    };

    handleSubmitGoogle = (googleUser) => {        
        const profile = googleUser.getBasicProfile();
        const email = profile.getEmail();
        this.props.loginGoogle(email);
    }

    handleErrorGoogle = () => {
        this.props.loginErro('Não foi possível realizar o login com o Google. Tente novamente mais tarde');        
    }

    
    render() {
        const { getFieldDecorator } = this.props.form;
        const { erroLogin, mensagem, loading } = this.props;
        
        return (
            <div id='login' className='wrapper'>                
                <div className='wp-content'>
                    <div className='content'>
                        <Card title={<CardTitle />} className='card-login-form'>
                            <Spin spinning={loading} indicator={iconLoading}>
                                <Form onSubmit={this.handleSubmit} className="login-form">
                                    <Form.Item>
                                        {getFieldDecorator('email', {
                                            rules: [
                                                { required: true, message: 'Informe seu e-mail' },
                                                { validator: Validacao.email, message: 'Informe um e-mail válido' }
                                            ],
                                        })(
                                            <Input
                                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                placeholder="E-mail"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: 'Informe sua senha' }],
                                        })(
                                            <Input
                                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                                type="password"
                                                placeholder="Senha"
                                            />,
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        { erroLogin &&
                                            <Alert message={mensagem} type="error" showIcon />
                                        }
                                    </Form.Item>
                                    
                                    <Form.Item>
                                        {getFieldDecorator('remember', {
                                            valuePropName: 'checked',
                                            initialValue: true,
                                        })(<Checkbox>Lembre-me</Checkbox>)}
                                        <Button className='login-form-forgot' type='link'>Esqueceu a senha</Button>
                                        <Button type='primary' htmlType='submit' className='login-form-button'> Login</Button>                    
                                        <GoogleLogin
                                            clientId='1033383972636-q188s0a0c5n098i37u5s1rp66s4s9rti.apps.googleusercontent.com'
                                            buttonText='Logar com o Gmail'
                                            theme='dark'
                                            onSuccess={this.handleSubmitGoogle}
                                            onFailure={this.handleErrorGoogle}
                                            cookiePolicy='single_host_origin'
                                            className='btn-google'
                                            
                                        />
                                    </Form.Item>
                                </Form>
                            </Spin>
                        </Card> 
                    </div>
                </div>                
            </div>
        );
    }
}

const CardTitle = () => 
    <div className='logo'>
        <Icon className='icon' type="paper-clip" />
        <h1 className='titulo'>Ac Escolar</h1>
    </div>

const iconLoading = <Icon type="loading" style={{ fontSize: 32 }} spin />;

const FormLogin = Form.create()(Login);

export default connect( state => ({ ...state.Auth}), {...AuthActions})(FormLogin);
