

const Dominio = {

    TIPO_USUARIO: 1,
    SERIES: 2,
    DISCIPLINAS: 3,
    TIPO_CHECKPOINT: 4,

    LISTAS: {
        
    },

    ID_DADOS_DOMINIO: {
        TIPO_CHECKPOINT: {
            POSITIVO: 44,
            ALERTA: 45,
            OBSERVACAO: 46,
        }
        
    },

    getDadosDominio: (dominios, ...idsDominio) => {
        if (idsDominio.length === 1) {
            const dominio = Dominio.getDominio(dominios, idsDominio[0]);
            return dominio && dominio.dados_dominio ? dominio.dados_dominio : [];
        }

        const arrDominios = idsDominio.map(id => {
            const dominio = Dominio.getDominio(dominios, id);
            return dominio && dominio.dados_dominio ? dominio.dados_dominio : [];
        });

        let retornoDominios = [];
        arrDominios.map((arr, idx) => {
            if (idx === 0)
                retornoDominios = arr;
            else
                retornoDominios = [...retornoDominios, ...arr];
            return false;
        })

        return retornoDominios;


    },

    getDadoDominioById: (dominios, idDominio, ...idsDadoDominio) => {
        return Dominio.getDadosDominio(dominios, idDominio).filter(d => {
            for (let idx = 0; idx < idsDadoDominio.length; idx++) {
                if (d.id === parseInt(idsDadoDominio[idx]))
                    return true;
            }
            return false;
        });
    },

    getDadosDominioComOpcaoTodos: (dominios, id) => {
        const dominio = Dominio.getDominio(dominios, id);
        return dominio && dominio.dados_dominio ? [{ id: -1, nome: 'Todos' }, ...dominio.dados_dominio] : [];
    },

    getDominio: (dominios, id) => {
        return dominios && dominios.filter(d => d.id === id)[0];
    },

    getStatus: () => [{ val: -1, text: 'Todos' }, { val: 1, text: 'Ativo' }, { val: 0, text: 'Inativo' }],
    getSimNao: () => [{ val: 1, text: 'Sim' }, { val: 0, text: 'Não' }],
    getSexo: () => [{ val: 'M', text: 'Masculino' }, { val: 'F', text: 'Feminino' }],
    getSimNaoTexto: (param) => param === 1 ? 'Sim' : param === 0 ? 'Não' : '',
    getSexoTexto: (sexo) => sexo === 'M' ? 'Masculino' : sexo === 'F' ? 'Feminino' : '',
    getVazioSimNao: () => [{ val: -1, text: ' ' }, { val: 1, text: 'Sim' }, { val: 0, text: 'Não' }],
    
    getIdsDominio: (dominios) => {
        if (!dominios) return;

        return dominios.map(d => d.id);
    }

};



export default Dominio;