import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Icon, Spin } from 'antd';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';


import Sidebar from './layout/sidebar';
import Topbar from './layout/topbar';
import LayoutContentWrapper from './layout/layout-content-wrapper';
import LayoutContent from './layout/layout-content';
import Theme from './layout/theme';
import AppHolder from './layout/common-style';
import Router from '../Router';
import AppActions from '../../redux/app/actions';

const { Content, Footer } = Layout;



export class Index extends Component {
    
    H_MARGIN_PADDING = 168;

  	render() {
                   
        const { height, loading } = this.props;    
	  	const appHeight = window.innerHeight;
            
    	return (
            <ThemeProvider theme={Theme}>
                <AppHolder>
                    <Layout style={{ height: appHeight }}>
                        <Debounce time="1000" handler="onResize">
                            <WindowResizeListener onResize={windowSize => this.props.toggleAll(windowSize.windowWidth, windowSize.windowHeight) } />
                        </Debounce>
                        <Topbar />
                        <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                            <Sidebar />
                            <Layout className="isoContentMainLayout" style={{ height }} >
                                <Content className="isomorphicContent">			                                                
                                    <Spin spinning={loading} indicator={iconLoading}>
                                        <LayoutContentWrapper>
                                            <LayoutContent style={{ minHeight: height - this.H_MARGIN_PADDING }}>	                                                                
                                                <Router />
                                            </LayoutContent>
                                        </LayoutContentWrapper>
                                    </Spin>
                                </Content>
                                <Footer> Acompanhamento Escolar ©2019 Criado por Dennys Marinho </Footer>
                            </Layout>
                        </Layout>                
                    </Layout>
                </AppHolder>
            </ThemeProvider>
    	);
  	}
}

const iconLoading = <Icon type="loading" style={{ fontSize: 64 }} spin />;

export default connect(state => ({ ...state.App }), { toggleAll: AppActions.toggleAll })(Index);
