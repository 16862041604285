import { ConstantesActions, getView } from "./actions";


const initState = {
    collapsed: window.innerWidth > 1220 ? false : true,
    view: getView(window.innerWidth),
    height: window.innerHeight,
    openDrawer: false,
    loading: false 
};

export default function appReducer(state = initState, action) {
    
    switch (action.type) {
        case ConstantesActions.COLLPSE_CHANGE:
            return { ...state, collapsed: !state.collapsed };

        case ConstantesActions.COLLPSE_OPEN_DRAWER:
            return { ...state, openDrawer: !state.openDrawer };

        case ConstantesActions.TOGGLE_ALL:
            if (state.view !== action.view || action.height !== state.height) {
                const height = action.height ? action.height : state.height;
                return { ...state, collapsed: action.collapsed, view: action.view, height };
            }
            return state;            

        case ConstantesActions.CHANGE_OPEN_KEYS:
            return { ...state, openKeys: action.openKeys };

        case ConstantesActions.CHANGE_CURRENT:
            return { ...state, current: action.current };

        case ConstantesActions.CLEAR_MENU:
            return { ...state, openKeys: [], current: [] };

        case ConstantesActions.LOADING:
        case ConstantesActions.LOADING_OFF:
            return { ...state, ...action.payload };
        
        default:
            return state;
    }  
}
