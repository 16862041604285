import HttpActions from '../http/actions';
import { URLS } from '../../utils';


export const ConstantesActions = {    
    CARREGAR_TURMAS: 'CARREGAR_TURMAS',
    SELECIONAR_TURMA: 'SELECIONAR_TURMA',
    MUDAR_ETAPA: 'MUDAR_ETAPA',
    CARREGAR_ALUNOS_TURMA: 'CARREGAR_ALUNOS_TURMA',
    SELECIONAR_ALUNO: 'SELECIONAR_ALUNO',
    CLEAR: 'CLEAR',

    ETAPA: {
        SELECIONAR_TURMA: 'SELECIONAR_TURMA',
        SELECIONAR_ALUNO: 'SELECIONAR_ALUNO',
        CHECKPOINT_ALUNO: 'CHECKPOINT_ALUNO',
        CHECKPOINT_REALIZADO: 'CHECKPOINT_REALIZADO',
    } 
}

const Actions = {    

    getTurmasDoProfessor: () => HttpActions.get({ URL: URLS.SERVER.CHECKPOINT.TURMAS_DO_PROFESSOR, retorno: Actions.carregarTurmas }),
    carregarTurmas: (dadosTurmas) => ({ type: ConstantesActions.CARREGAR_TURMAS, payload: { ...dadosTurmas } }),
    selecionarTurma: (turmaSelecionada) => ({ type: ConstantesActions.SELECIONAR_TURMA, payload: { turmaSelecionada, alunos: [] } }),
    getAlunosDaTurma: (idTurma) => HttpActions.get({ URL: `${URLS.SERVER.CHECKPOINT.ALUNOS_DA_TURMA}/${idTurma}`, retorno: Actions.carregarAlunosTurma }),
    showEtapaSelecionarTurma: () => Actions.__mudarEtapa(ConstantesActions.ETAPA.SELECIONAR_TURMA),
    showEtapaSelecionarAluno: () => Actions.__mudarEtapa(ConstantesActions.ETAPA.SELECIONAR_ALUNO),
    showEtapaCheckpointAluno: () => Actions.__mudarEtapa(ConstantesActions.ETAPA.CHECKPOINT_ALUNO),
    showEtapaCheckpointRealizado: () => Actions.__mudarEtapa(ConstantesActions.ETAPA.CHECKPOINT_REALIZADO),
    __mudarEtapa: (etapa) => ({ type: ConstantesActions.MUDAR_ETAPA, payload: { etapa }}),
    carregarAlunosTurma: (alunos) => ({ type: ConstantesActions.CARREGAR_ALUNOS_TURMA, payload: { alunos } }),
    selecionarAluno: (alunoSelecionado) => ({ type: ConstantesActions.SELECIONAR_ALUNO, payload: { alunoSelecionado, etapa: ConstantesActions.ETAPA.CHECKPOINT_ALUNO} }),    
    postSalvarCheckpointAluno: (params) => HttpActions.post({ URL: URLS.SERVER.CHECKPOINT.SALVAR_CHECKPOINT_ALUNO, params, retorno: Actions.showEtapaCheckpointRealizado }), 
    clear: (etapa) => ({ type: ConstantesActions.CLEAR }),
};

export default Actions;