import React, { Component, Fragment } from 'react'
import { Typography, Row, Col, Card, Avatar, Icon, Modal, Result } from 'antd';
import { connect } from 'react-redux';

import CheckpointsPendentesActions, { ConstantesActions } from '../../redux/checkpoints-pendentes/actions';
import ModalCheckPointAluno from '../../componentes/modal-checkpoint'
import { Formatacao } from '../../utils';
import TituloPagina from '../../componentes/titulo-pagina';


class CheckpointsPendentes extends Component {

    constructor(props) {
        super(props);        
        props.getCheckpointsPendentes();
    }

    selecionarCheckPoint = (checkpoint) => {
        this.props.selecionarCheckPoint(checkpoint);
    }

    checkpoint = (checkpoint) => {
        const { selecionarCheckPointSemEtapa, postAtualizarCheckpointAluno } = this.props;        
        selecionarCheckPointSemEtapa(checkpoint);
        postAtualizarCheckpointAluno(checkpoint);
    }

    okModalSuccess = (close) => {
        this.props.showEtapaSelecionarCheckpoint();
        close();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps.etapa === ConstantesActions.ETAPA.CHECKPOINT_REALIZADO && this.props.etapa === ConstantesActions.ETAPA.SELECIONAR_CHECKPOINT)
            this.props.getCheckpointsPendentes();

        if ((prevProps.etapa === ConstantesActions.ETAPA.CHECKPOINT_SELECIONADO || prevProps.etapa === ConstantesActions.ETAPA.CHECKPOINT_SELECIONADO_SEM_MODAL) 
            && this.props.etapa === ConstantesActions.ETAPA.CHECKPOINT_REALIZADO){
            Modal.success({ title: 'Checkpoint Realizado!', content: `Aluno: ${this.props.checkpointSelecionado.aluno.nome}`, onOk: this.okModalSuccess });        
        }

    }

    render() {            
        return (
            <Fragment>
                <TituloPagina titulo='Checkpoints Pendentes' subTitulo='Adicione as informações restantes aos checkpoints' />
                <Checkpoints checkpoints={this.props.checkpoints} checkpoint={this.checkpoint} selecionarCheckPoint={this.selecionarCheckPoint} loading={this.props.loading} />
                <ModalCheckPointAluno 
                    cancelar={this.props.showEtapaSelecionarCheckpoint}
                    inicioCheckPoint={this.props.showEtapaSelecionarCheckpoint}
                    salvar={this.props.postAtualizarCheckpointAluno}
                    showModal={this.props.etapa === ConstantesActions.ETAPA.CHECKPOINT_SELECIONADO}
                    showSucesso={this.props.etapa === ConstantesActions.ETAPA.CHECKPOINT_REALIZADO}
                    objCheckpoint={this.props.checkpointSelecionado}
            />
            </Fragment>
        );
    }
}

const Checkpoints = (props) => {

    const getNomeTurma = (checkpoint) => `${checkpoint.aluno.turma.nome} - ${checkpoint.aluno.turma.dom_serie.nome}`;
    
    const carrgarCheckpointsPendentes = () =>
        props.checkpoints && props.checkpoints.length > 0 ?
            props.checkpoints.map(checkpoint =>
                <Col key={checkpoint.id} xs={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 8 }}>
                    <Card hoverable={true}  actions={[                        
                        <Icon type="edit" key="edit" onClick={() => props.selecionarCheckPoint(checkpoint)} />,
                        <Icon type="check-circle" onClick={() => props.checkpoint(checkpoint)} />,
                    ]}>
                        <Row gutter={[16, 16]}>
                            <Col key={1} xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                <Avatar shape="square" size={64} icon="user" src={checkpoint.aluno.avatar} />
                            </Col>                            
                            <Col style={{ textAlign: 'left' }} key={2} xs={{ span: 0 }} md={{ span: 20 }} lg={{ span: 20 }} xl={{ span: 20 }}>
                                <Row >
                                    <Col span={24}>
                                        <Typography.Title style={{ fontSize: 16 }} level={4}>{checkpoint.aluno.nome}</Typography.Title>
                                    </Col>
                                    <Col span={24}>
                                        <Typography.Text level={4}><Typography.Text strong>Turma: </Typography.Text>{getNomeTurma(checkpoint)}</Typography.Text>
                                    </Col>
                                    <Col span={24}>
                                        <Typography.Text level={4}><Typography.Text strong>Data: </Typography.Text>{Formatacao.dataHoraBR(checkpoint.dt_checkpoint)}</Typography.Text>
                                    </Col>
                                </Row>
                            </Col>
                            <Col style={{ textAlign: 'center' }} key={3} xs={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 0 }}>
                                <Row >
                                    <Col span={24}>
                                        <Typography.Title style={{ fontSize: 16 }} level={4}>{checkpoint.aluno.nome}</Typography.Title>
                                    </Col>
                                    <Col span={24}>
                                        <Typography.Text level={4}><Typography.Text strong>Turma: </Typography.Text>{getNomeTurma(checkpoint)}</Typography.Text>
                                    </Col>
                                    <Col span={24}>
                                        <Typography.Text level={4}><Typography.Text strong>Data: </Typography.Text>{Formatacao.dataHoraBR(checkpoint.dt_checkpoint)}</Typography.Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>                        
                    </Card>
                </Col>
            )
            :
                !props.loading ? <Result status='success' title='Você não possui checkpoints pendentes' /> : null;

    return (
        <Row gutter={[16, 16]}>
            {carrgarCheckpointsPendentes()}
        </Row>
    )
}

export default connect(state => ({ ...state.CheckPointsPendentes, ...state.App }), { ...CheckpointsPendentesActions })(CheckpointsPendentes);