
const actions = {
	// LOGIN
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_GOOGLE_REQUEST: 'LOGIN_GOOGLE_REQUEST',
  	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',

	// CHECK_AUTHORIZATION
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
	// LOGOUT
  	LOGOUT: 'LOGOUT',
	// FORGOT_PASSWORD
	FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
	FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
	FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
	// RESET_PASSWORD
	FIND_INFO_USER_BY_TOKEN: 'FIND_INFO_USER_BY_TOKEN',
	SET_INFO_TOKEN: 'SET_INFO_TOKEN',
	SET_INFO_TOKEN_ERROR: 'SET_INFO_TOKEN_ERROR',
	RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
	// CLEAR
	CLEAR: 'CLEAR',

  	checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
    login: (params) => ({ type: actions.LOGIN_REQUEST, payload: {...params, erroLogin: false }}),
	loginGoogle: (email) => ({ type: actions.LOGIN_GOOGLE_REQUEST, payload: {email, erroLogin: false }}),    
	loginErro: (mensagem) => ({ type: actions.LOGIN_ERROR, payload: {mensagem, erroLogin: true }}),    
	hideLoadingLogin: () => ({ type: actions.HIDE_LOADING_LOGIN, payload: { loading: false }}),
	forgotPassword: (params) => ({ type: actions.FORGOT_PASSWORD_REQUEST, payload: params }),
	findInfoUserByToken: (params) => ({ type: actions.FIND_INFO_USER_BY_TOKEN, payload: params }),
	setInfoToken: (params) => ({ type: actions.SET_INFO_TOKEN, payload: params }),
	resetPassword: (params) => ({ type: actions.RESET_PASSWORD_REQUEST, payload: params }),
	logout: () => ({ type: actions.LOGOUT }),
	clear: () => ({ type: actions.CLEAR })
};


export default actions;

