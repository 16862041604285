import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { createLogger }  from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';
import AppActions from './app/actions'

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
	predicate: (getState, action) => Object.keys(AppActions).filter(actionType => actionType === action.type).length === 0
})

const middlewares = [sagaMiddleware, logger];

const composeEnhancers =
  	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      	})
    	: compose;

const store = createStore(
  	combineReducers({ ...reducers }),
  	composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);
export { store, history };
