import React from 'react';
import { PageHeader } from 'antd';


export default (props) => 
    <PageHeader
        style={{ border: '1px solid rgb(235, 237, 240)', marginBottom: 15 }}        
        title={props.titulo}
        subTitle={props.subTitulo}
        {...props}
    />