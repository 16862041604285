import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import './index.css';
import InnerApp from './App';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import PT_BR from 'antd/lib/locale-provider/pt_BR';
import 'moment/locale/pt-br';


const App = () => 
    <Provider store={store}>
        <ConfigProvider locale={PT_BR}> 
            <BrowserRouter>
                <InnerApp />
            </BrowserRouter>
        </ConfigProvider>
    </Provider>


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
