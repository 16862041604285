import React from 'react';
import { connect } from 'react-redux';

import './App.css';

import AuthActions from './redux/auth/actions'

import Index from './paginas/index/';
import Login from './paginas/login/';


class App extends React.Component {

    constructor(props){
        super(props)
        props.checkAuthorization();
    }
    
    render(){
        return (
            <div className="App">                
                { this.props.Auth.usuario ? <Index /> : <Login /> }                
            </div>
        );
    }
};


export default connect(state => ({ Auth: state.Auth }), { ...AuthActions })(App);