import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Icon } from "antd";
import appActions from "../../../../redux/app/actions";
import TopbarUser from "./topbar-user";
import TopbarWrapper from "./topbar.style";

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {
    
    render() {
        const { toggleCollapsed, customizedTheme } = this.props;
        const collapsed = this.props.collapsed && !this.props.openDrawer;
        const styling = {
            background: customizedTheme.backgroundColor,
            position: "fixed",
            width: "100%",
            height: 70
        };
    
        return (
            <TopbarWrapper>
                <Header style={styling} className={ collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar" }>
                    <div className="isoLeft">
                        <Icon type="menu" size='large' className={ collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen" } style={{ color: customizedTheme.textColor, fontSize: 64 }} onClick={toggleCollapsed} />
                    </div>
                    <TopbarUser className="isoRight" />
                </Header>
            </TopbarWrapper>
        );
    }
}

export default connect( state => ({ ...state.App, customizedTheme: 'themedefault' }), { toggleCollapsed } )(Topbar);
