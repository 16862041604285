

const HttpActions = {

    GET_REQUEST: 'GET_REQUEST',
    POST_REQUEST: 'POST_REQUEST',
    PUT_REQUEST: 'PUT_REQUEST',
    DELETE_REQUEST: 'DELETE_REQUEST',      


    get: (params) => HttpActions.__request(HttpActions.GET_REQUEST, params),
    post: (params) => HttpActions.__request(HttpActions.POST_REQUEST, params),
    put: (params) => HttpActions.__request(HttpActions.PUT_REQUEST, params),
    del: (params) => HttpActions.__request(HttpActions.DELETE_REQUEST, params),

    
    __request : (method, params) => ({ type: method, payload: { ...params } })

} 

export default HttpActions;