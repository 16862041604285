import { ConstantesActions } from "./actions";

const initState = { };

export default function checkPointAlunoReducer(state = initState, action) {
    
    switch (action.type) {
        
        case ConstantesActions.CARREGAR_TURMAS:
        case ConstantesActions.SELECIONAR_TURMA:
        case ConstantesActions.CARREGAR_ALUNOS_TURMA:
        case ConstantesActions.MUDAR_ETAPA:
        case ConstantesActions.SELECIONAR_ALUNO:
            return { ...state, ...action.payload }
            
        case ConstantesActions.CLEAR:
            return { turmas: state.turmas }


        default:
            return state;
  }  
}
