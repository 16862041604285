import React, { Component, Fragment } from 'react'
import { Result, Icon, Typography, Row, Col, Card, Input, Avatar, Modal } from 'antd';
import { connect } from 'react-redux';

import ModalCheckPointAluno from '../../componentes/modal-checkpoint'
import CheckPointAlunoActions, { ConstantesActions } from '../../redux/checkpoint-aluno/actions';
import { Filtro } from '../../utils';

class Alunos extends Component {

    constructor(props){
        super(props);
        this.state = {};
        
        if (props.turmaSelecionada)
            props.getAlunosDaTurma(props.turmaSelecionada.id);
        else
            props.showEtapaSelecionarTurma();
    }

    getNomeTurmaSelecionada = () => this.props.turmaSelecionada ? `${this.props.turmaSelecionada.nome} - ${this.props.turmaSelecionada.dom_serie.nome}` : '';

    selecionarAluno = (aluno) => {
        this.props.selecionarAluno(aluno);
    }

    inicioCheckPoint = () =>{        
        this.props.showEtapaSelecionarAluno();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
        if(!this.props.turmaSelecionada)
            this.props.showEtapaSelecionarTurma();            
    
        if (prevProps.etapa === ConstantesActions.ETAPA.CHECKPOINT_ALUNO && this.props.etapa === ConstantesActions.ETAPA.CHECKPOINT_REALIZADO)
            Modal.success({ title: 'Checkpoint Realizado!', content: `Aluno: ${this.props.alunoSelecionado.nome}`, onOk: this.inicioCheckPoint });
    }

    onChangePesquisa = (e) => this.setState({valorPesquisaAluno: e.target.value});

    render(){
        return(
            <Fragment>
                <Result
                    icon={<Icon type="team" />}
                    title={<Titulo nomeTurma={this.getNomeTurmaSelecionada()} />}
                    subTitle="Click em um aluno e realize o checkpoint."
                    extra={<Controles alunos={this.props.alunos} selecionarAluno={this.selecionarAluno} valorPesquisaAluno={this.state.valorPesquisaAluno} onChangePesquisa={this.onChangePesquisa}/> }
                />
                
                <ModalCheckPointAluno
                    cancelar={this.props.showEtapaSelecionarAluno}
                    inicioCheckPoint={this.inicioCheckPoint}
                    salvar={this.props.postSalvarCheckpointAluno}
                    showModal={this.props.etapa === ConstantesActions.ETAPA.CHECKPOINT_ALUNO}
                    loading={this.props.loading}                     
                    objCheckpoint={this.props.alunoSelecionado ? { aluno: this.props.alunoSelecionado} : null}
                />
            </Fragment>
        );
    }
}

const Titulo = (props) =>
    <h3 style={{ color: '#3490FF' }}>{props.nomeTurma}</h3>


const Controles = (props) => {

    const carrgarAlunos = () => {        
        const alunos = Filtro.texto(props.alunos, 'nome', props.valorPesquisaAluno);
        return alunos.map(a => 
            <Col key={a.id} xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                <Card hoverable={true} onClick={() => props.selecionarAluno(a)}>
                    <Avatar shape="square" size={64} icon="user" src={a.avatar}/>
                    <Typography.Title className='nome-aluno' level={4}>{a.nome}</Typography.Title>
                </Card>
            </Col>
        )
    }
        
    return props.alunos && props.alunos.length > 0 ?
        <Fragment>
            <Row gutter={[16, 24]} style={{marginBottom: 20}}>
                <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 20, push: 2 }} xl={{ span: 10, push: 7 }}>
                    <Input.Search placeholder="Pesquise pelo nome do Aluno" onChange={(props.onChangePesquisa)} />                    
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                {carrgarAlunos()}
            </Row>
        </Fragment>
    : null;
}


export default connect(state => ({ ...state.CheckPointAluno, ...state.App }), { ...CheckPointAlunoActions} )(Alunos);