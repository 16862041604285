import React from 'react';
import { Link } from 'react-router-dom';


export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/adm">
              {/* <img src={logoCEANSG} width={50} height={50} alt='Logo CEANSG' /> */}
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
            <Link to="/adm">
                {/* <img src={logoCEANSG} width={40} height={40} alt='Logo CEANSG' />     */}
              <span style={{ marginLeft: '20px', verticalAlign: 'middle'}}>Ac Escolar</span> 
            </Link>
        </h3>
      )}
    </div>
  );
};
