import { all, fork } from 'redux-saga/effects';


import HttpSagas from './http/saga'
import authSagas from './auth/saga';

export default function* rootSaga(getState) {
  yield all([    
      fork(authSagas),
      fork(HttpSagas),
  ]);
}