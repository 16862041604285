import moment from 'moment';
import numeral from 'numeral';
import Dominio from './dominio';


// load a locale
numeral.register('locale', 'pt-BR', {
    delimiters: { thousands: '.', decimal: ',' },
    abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
    ordinal: function (number) {
        return number === 1 ? 'real' : 'reais';
    },
    currency: { symbol: 'R$' }
});

numeral.locale('pt-BR');

const Formatacao = {
    dataDMYBR: (dia, mes, ano) => (dia && mes && ano) ? moment({d: dia, M: (mes-1), Y: ano}).format('DD/MM/YYYY') : null,
    dataBR: (data) => data ? moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
    dataMMYYYY: (data) => data ? moment(data, 'YYYY-MM-DD').format('MM/YYYY') : null,
    dataHoraBR: (data) => data ? moment(data, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD/MM/YYYY') : null,
    dataUSAno: (data) => data ? moment(data, 'YYYY-MM-DD').format('YYYY') : null,
    dataAtualBRFile: () => moment(new Date()).format('DD-MM-YYYY'),
    dataDiaSemanaDataPorExtenso: (data) => data ? moment(data, 'YYYY-MM-DD').format('dddd, DD [de] MMMM [de] YYYY') : null,
    nomesDominios: (dominios) => dominios ? dominios.map(d => d.nome).join(', ') : '',

    getItemFiltro: (valorFiltro, nomeFiltro, dominios, ...idsDominio) => {        
        if (valorFiltro && valorFiltro.length > 0) {
            let dadosDominio = [];
            for (let idx = 0; idx < idsDominio.length; idx++){
                if(typeof valorFiltro === 'object')
                    dadosDominio = [...dadosDominio, ...Dominio.getDadoDominioById(dominios, idsDominio[idx], ...valorFiltro)];
                else
                    dadosDominio = [...dadosDominio, ...Dominio.getDadoDominioById(dominios, idsDominio[idx], valorFiltro)];
            }

            const nomeDominio = Formatacao.nomesDominios(dadosDominio);
            if (nomeDominio)
                return { nome: nomeFiltro, valor: nomeDominio };            
        }
        return null;
    },

    getItemFiltroText: (valorFiltro, nomeFiltro, dominios, ...idsDominio) => {
        const filtro = Formatacao.getItemFiltro(valorFiltro, nomeFiltro, dominios, ...idsDominio);
        return filtro ? `| ${filtro.nome}: ${filtro.valor}` : null;
    },

    getItemFiltroSitucao: (valor, nome) => {
        if(valor === 1)
            return { nome, valor: 'Ativo' };
        else if(valor === 0)
            return { nome, valor: 'Inativo' };
        else 
            return null;        
    },

    real: (valor) => valor ? numeral(valor).format('$ 0,0.00') : numeral(0).format('$ 0,0.00')
};

export default Formatacao;