
const Validacao = {
    cpf: (_, value, callback) => {        
        if (Validacao.__cpf(value))
            callback();
        callback('CPF inválido');
    },

    cep: (_, value, callback) => {            
        if (!value || value.length === 9)
            callback();
        callback('CEP inválido');
    },

    email: (_, value, callback) => { 
        var patt = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;       
        if (!value || patt.test(value))
            callback();
        callback('E-mail inválido');
    },

    requiredSelectTwo: (_, value, callback) => {
        if (value && value.valOne && value.valTwo)
            callback();
        callback('O campo é obrigatório');
    },

    tamanhoArquivo10mb: (_, value, callback) => {              
        if (!value || !value.file)
            callback();
        else if(value.file.size / 1000000 > 10)        
            callback('O arquivo não pode ser maior que 10mb')
        callback()

    },

    numeroFicha: (rule, value, callback) => {                
        const fichas = rule.mediuns.map(m => m.pivot.num_inicial_ficha );
        const num_inicial_ficha = value;        
        
        if(!num_inicial_ficha)
            callback();

        fichas.forEach( ficha => {
            if (ficha === num_inicial_ficha){
                callback('O número inicial da ficha já esta sendo utilizado');
                return;
            }
        });

        callback();
    },

    __cpf: (strCPF) => {
        var Resto, Soma = 0;        
        
        if (!strCPF) return true;
        
        strCPF = strCPF.replace(/[.]|[-]/g, '');
        
        if (strCPF === "00000000000") return false;

        for (var idx = 1; idx <= 9; idx++) Soma = Soma + parseInt(strCPF.substring(idx - 1, idx)) * (11 - idx);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (var idx2 = 1; idx2 <= 10; idx2++) Soma = Soma + parseInt(strCPF.substring(idx2 - 1, idx2)) * (12 - idx2);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        
        return true;
    }
};


export default Validacao;