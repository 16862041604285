import { put, takeEvery, all, fork, call } from 'redux-saga/effects';

import axios from './rest';
import SagaActions from './actions';
import AppActions from '../app/actions';
//import { Mensagem } from '../../utils'

const METHOD_HTTP = { GET : 'get', POST: 'post', PUT: 'put', DELETE: 'delete' }

export function* httpGET() {
    yield takeEvery(SagaActions.GET_REQUEST, hadleRequest, METHOD_HTTP.GET);
}

export function* httpPOST() {
    yield takeEvery(SagaActions.POST_REQUEST, hadleRequest, METHOD_HTTP.POST);
}

export function* httpPUT() {
    yield takeEvery(SagaActions.PUT_REQUEST, hadleRequest, METHOD_HTTP.PUT);
}

export function* httpDELETE() {
    yield takeEvery(SagaActions.DELETE_REQUEST, hadleRequest, METHOD_HTTP.DELETE);
}


const hadleRequest = function* (method, action) {
    
    const { retorno, URL, params, nomVariavel, naoLoading } = yield action.payload;        
    
    if(!naoLoading)
        yield put(AppActions.loading());
    
    try {
        const response = yield call(axios[method], URL, (method === METHOD_HTTP.GET || method === METHOD_HTTP.DELETE) ? ({ params }) : params);              
    
        if(nomVariavel)
            yield put(retorno(nomVariavel, response.data));        
        else    
            yield put(retorno(response.data));            
    } catch (erro) { 
        //const msgErroDefault = yield 'Ocorreum um erro, tente novamente mais tarde.';
        //const msgErro = yield (erro.response && erro.response.data) ? erro.response.data : msgErroDefault;
        //const mensagem = yield Mensagem.erro('Ocorreu um erro.', msgErro);            
        //yield put(AppActions.showMensagem(mensagem));
    }
                
    if (!naoLoading)
        yield put(AppActions.loadingOff());
}


export default function* rootSaga() {
    yield all([
        fork(httpGET),
        fork(httpPOST),
        fork(httpPUT),
        fork(httpDELETE)
    ]);
}