import styled from 'styled-components';
import { palette } from 'styled-theme';

const AppHolder = styled.div`
  	-webkit-overflow-scrolling: touch;
  	.trigger {
		font-size: 18px;
		line-height: 64px;
		padding: 0 16px;
		cursor: pointer;
		transition: color 0.3s;
	}

  	.trigger:hover {
    	color: ${palette('primary', 0)};
  	}

  	.ant-layout-sider-collapsed .anticon {
    	font-size: 16px;
  	}

  	.ant-layout-sider-collapsed .nav-text {
    	display: none;
  	}

  	.ant-layout {
    	background: ${palette('secondary', 1)};

    	&.isoContentMainLayout {
      		overflow: auto;
      		overflow-x: hidden;
      		@media only screen and (min-width: 768px) and (max-width: 1220px) {
				width: calc(100% - 80px);
				flex-shrink: 0;
      		}

      		@media only screen and (max-width: 767px) {
				width: 100%;
				flex-shrink: 0;
      		}
		}
  	}

  	.isoLayoutContent {
    	width: 100%;
		padding: 35px;
		background-color: #ffffff;
		border: 1px solid ${palette('border', 0)};
		height: 100%;
  	}

  	.isomorphicLayout {
		width: calc(100% - 240px);
		flex-shrink: 0;
		overflow-x: hidden !important;

		@media only screen and (max-width: 767px) {
			width: 100%;
		}

		@media only screen and (min-width: 768px) and (max-width: 1220px) {
			width: calc(100% - 80px);
			width: 100%;
		}
  	}
  
  	.ant-layout-content {
		-ms-flex: auto;
		flex: auto;
		padding: 40px 0 0;
		flexShrink: 0;
		background: #f1f3f6;
        position: relative;
        overflow-y: auto;
  	}

	.ant-header-page {
		margin-bottom: 10px;
	}

	.form-filter {
  		padding: 10px 24px;
  		background: #fbfbfb;
  		border: 1px solid #d9d9d9;
		border-radius: 6px;		
		margin-bottom: 15px;
		width: 100%;
		float: left;
	}

	.form-filter .ant-form-item {
		display: flex;
		margin-bottom: 0;
	}
	
	.form-filter .buttons {
		text-align: right;
		margin-top: 10px;
		padding-right: 16px;
	}

	.form-filter .ant-form-item-control-wrapper {
  		flex: 1;
	}
  	.ant-layout-footer {
		font-size: 13px;
		background: #fff; 
		text-align: center;
		border-top: 1px solid #ededed;

		@media (max-width: 767px) {
			padding: 10px 20px;
		}
	  }
	  
	  /* you can make up upload button and sample style by using stylesheets */
	.ant-upload-select-picture-card i {
  		font-size: 32px;
  		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
  		margin-top: 8px;
  		color: #666;
	}
	
`;

export default AppHolder;
