import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import './styles.css';

import { ConstantesActions } from '../../redux/checkpoint-aluno/actions';
import Turmas from './turmas';
import Alunos from './alunos';
import TituloPagina from '../../componentes/titulo-pagina';
import CheckPointAlunoActions from '../../redux/checkpoint-aluno/actions';



class CheckPointAluno extends Component {

    constructor(props){
        super(props);        
        props.getTurmasDoProfessor();
    }

    alterarTurma = () => {
        this.props.selecionarTurma(null);
    }

    render(){        
        return (
            <div id='aluno-checkpoint'>
                <TituloPagina titulo='Checkpoint' subTitulo='Realize o checkpoint do aluno' extra={[<Button onClick={this.alterarTurma} type="primary">Alterar Turma</Button>]} />
                {!this.props.etapa || this.props.etapa === ConstantesActions.ETAPA.SELECIONAR_TURMA ? <Turmas /> : <Alunos />} 
            </div>
        )
    }
}

export default connect(state => ({ ...state.CheckPointAluno }), { ...CheckPointAlunoActions })(CheckPointAluno);