import React, { Component } from 'react'
import { Result, Button, Icon, Select, Row, Col } from 'antd';
import { connect } from 'react-redux';

import CheckPointAlunoActions from '../../redux/checkpoint-aluno/actions';


class Turmas extends Component {

    
    changeTurma = (idTurma) => {
        const { turmas, selecionarTurma } = this.props;
        const turmaSelecionada = turmas.filter(turma => turma.id === idTurma )[0]; 
        selecionarTurma(turmaSelecionada);
    }

    continuar = () => this.props.showEtapaSelecionarAluno();

    getNomeTurmaSelecionada = () => this.props.turmaSelecionada ? `${this.props.turmaSelecionada.nome} - ${this.props.turmaSelecionada.dom_serie.nome}` : '';

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.turmaSelecionada)
            this.props.showEtapaSelecionarAluno();
    }

    render(){
        return(
            <Result
                icon={<Icon type="team" />}
                title={<Titulo nomeTurma={this.getNomeTurmaSelecionada()} />}
                subTitle="Selecione a turma desejada e click em 'Continuar >' para realizar o checkpoint do aluno desejado."
                extra={<Controles turmas={this.props.turmas} onChange={this.changeTurma} continuar={this.continuar} /> }
            />
        );
    }
}

const Titulo = (props) =>
    <h3 style={{ color: '#3490FF' }}>{props.nomeTurma}</h3>


const Controles = (props) => {

    const carregarTurmasSelect = () => props.turmas && props.turmas.length > 0 ? 
        props.turmas.map(t => <Select.Option key={t.id} value={t.id}>{`${t.nome} - ${t.dom_serie.nome}`}</Select.Option>) : null;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={{ span: 24 }} md={{ span: 18 }} lg={{ span: 20 }} xl={{ span: 8, offset: 7 }}>
                <Select style={{ width: '100%' }} onChange={props.onChange}>
                    {carregarTurmasSelect()}                
                </Select>  
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 3 }}>
                <Button onClick={props.continuar} type='primary'>Continuar <Icon type='right' /></Button>
            </Col>
        
        </Row>
    )
}


export default connect(state => ({ ...state.CheckPointAluno }), { ...CheckPointAlunoActions })(Turmas);