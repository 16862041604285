//import HttpActions from '../http/actions';
//import { URLS } from '../../utils';


export const ConstantesActions = {
    COLLPSE_CHANGE: 'COLLPSE_CHANGE',
    COLLPSE_OPEN_DRAWER: 'COLLPSE_OPEN_DRAWER',
    TOGGLE_ALL: 'TOGGLE_ALL',
    CHANGE_OPEN_KEYS: 'CHANGE_OPEN_KEYS',
    CHANGE_CURRENT: 'CHANGE_CURRENT',
    CLEAR_MENU: 'CLEAR_MENU',
    LOADING: 'LOADING',
    LOADING_OFF: 'LOADING_OFF',
}

export const getView = (width) => {
    let newView = 'MobileView';
    if (width > 1220) {
        newView = 'DesktopView';
    } else if (width > 767) {
        newView = 'TabView';
    }
    return newView;
}

const Actions = {    
    
    toggleOpenDrawer: () => ({
        type: ConstantesActions.COLLPSE_OPEN_DRAWER
    }),
    toggleCollapsed: () => ({
        type: ConstantesActions.COLLPSE_CHANGE
    }),
    toggleAll: (width, height) => {
        const view = getView(width);
        const collapsed = view !== 'DesktopView';
        return {
            type: ConstantesActions.TOGGLE_ALL,
            collapsed,
            view,
            height
        };
    },
    changeOpenKeys: openKeys => ({
        type: ConstantesActions.CHANGE_OPEN_KEYS,
        openKeys
    }),
    changeCurrent: current => ({
        type: ConstantesActions.CHANGE_CURRENT,
        current
    }),
    clearMenu: () => ({ type: ConstantesActions.CLEAR_MENU }),

    loading: () => ({ type: ConstantesActions.LOADING, payload: { loading: true } }),
    loadingOff: () => ({ type: ConstantesActions.LOADING_OFF, payload: { loading: false } }),
    
};

export default Actions;