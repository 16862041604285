


const Filtro = {

    texto: (dados, atributo, valor) => {
        if (!valor) return dados;
        return dados.filter(item => {
            const texto = Filtro.__getValorColuna(item, atributo);
            return (texto ? texto : '').toUpperCase().indexOf(valor.toUpperCase()) > -1;
        });
    },
    valor: (dados, atributo, valor) => {
        if (!dados || !valor) return dados;

        return dados.filter(dado => {
            return Filtro.__getValorColuna(dado, atributo) === parseInt(valor);
        });
    },

    valorDiferente: (dados, atributo, valor) => {
        if (!dados || !valor) return dados;

        return dados.filter(dado => {
            return Filtro.__getValorColuna(dado, atributo) !== parseInt(valor);
        });
    },

    nValores: (dados, atributo, valoresFiltro) => {
        if (!dados || !valoresFiltro) return dados;

        return dados.filter(dado => {
            if (valoresFiltro.length === 1) {
                return Filtro.__getValorColuna(dado, atributo) === parseInt(valoresFiltro[0]);
            } else if (valoresFiltro.length > 1) {
                for (let idx = 0; idx < valoresFiltro.length; idx++) {
                    if (Filtro.__getValorColuna(dado, atributo) === parseInt(valoresFiltro[idx])) {
                        return true;
                    }
                }
                return false;
            } else
                return true;
        });
    },

    nValoresDiferente: (dados, atributo, valoresFiltro) => {
        if (!dados || !valoresFiltro) return dados;

        return dados.filter(dado => {
            if (valoresFiltro.length === 1) {
                return Filtro.__getValorColuna(dado, atributo) !== parseInt(valoresFiltro[0]);
            } else if (valoresFiltro.length > 1) {
                for (let idx = 0; idx < valoresFiltro.length; idx++) {
                    if (Filtro.__getValorColuna(dado, atributo) === parseInt(valoresFiltro[idx])) {
                        return false;
                    }
                }
                return true;
            } else
                return true;
        });
    },

    Tabela: {
        texto: (linha, coluna, valor) => {        
            const dado = Filtro.__getValorColuna(linha, coluna);
            return (dado ? dado : '').toUpperCase().indexOf(valor.toUpperCase()) > -1;
        },    

        valor: (linha, coluna, valor) => {
            const dado = Filtro.__getValorColuna(linha, coluna);                                      
            return parseInt(valor) !== -1 ? dado === parseInt(valor) : true;        
        },

        nValores: (linha, coluna, valores) => {            
            if (valores.length === 0)
                return true;
            for(let i = 0; i < valores.length; i++){
                let dado = Filtro.__getValorColuna(linha, coluna);                        
                if (dado === parseInt(valores[i]))
                    return true;            
            }
            return false;
        },        

        executeFiltros: (dados, filtros, valores) => {                
            if(!filtros) return dados;

            let lenFiltros = filtros.length;                            
            if(lenFiltros.length < 1)
                return dados;               
            return dados.filter(item => {
                for (let i = 0; i < lenFiltros; i++) {
                    let { func, coluna } = filtros[i];                      
                    let val = Filtro.__getValorColuna(valores, coluna);                        
                    if (val && !func(item, coluna, val)) {
                        return false;
                    }
                }
                return true
            });
        },

    },

    Select:{
        texto: (valor, option) => {
            const textoOption = option.props.children;
            return (textoOption ? textoOption : '').toUpperCase().indexOf(valor.toUpperCase()) > -1;
        },
    },    

    __getValorColuna: (linha, coluna) => {        
        if(coluna.indexOf('.') === -1)
            return linha[coluna];
        let retorno = linha;        
        let atributos = coluna.split('.');            
        for(let idx=0; idx < atributos.length; idx++)
            retorno = retorno[atributos[idx]]; 

        return retorno;
    }

};


export default Filtro;