import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popover, Avatar } from 'antd';
import authAction from '../../../../redux/auth/actions';
import TopbarDropdownWrapper from './topbar-dropdown.style'; 

const { logout } = authAction;

class TopbarUser extends Component {
  
	constructor(props) {
    	super(props);
		
		this.handleVisibleChange = this.handleVisibleChange.bind(this);
    	this.hide = this.hide.bind(this);
		this.state = { visible: false };
  	}

  	hide() {
    	this.setState({ visible: false });
	}
	
  	handleVisibleChange() {
    	this.setState({ visible: !this.state.visible });
  	}

  	render() {
    	const content = (
      		<TopbarDropdownWrapper className="isoUserDropdown">        	
        		<a href='#sair' className="isoDropdownLink" onClick={this.props.logout}>Sair</a>
      		</TopbarDropdownWrapper>
    	);

    	return (
      		<Popover
        		content={content}
        		trigger="click"
				visible={this.state.visible}
				onVisibleChange={this.handleVisibleChange}
				arrowPointAtCenter={true}
				placement="bottomLeft" >
					<div className="isoImgWrapper">
						<Avatar size='large' icon="user"/>                    
					</div>
      		</Popover>
    	);
  	}
}


export default connect(state => { return ({ ...state.Auth }) }, { logout })(TopbarUser);
