import React, { Component } from 'react';
import { Row, Col, Card, Form, Radio, Input, Avatar, Typography, Collapse } from 'antd';
import { connect } from 'react-redux';

import DefaultModal from './modal';
import { Dominio, Formatacao } from '../utils';


class ModalCheckPoint extends Component {


    cancelar = () => {
        this.props.cancelar();        
    }

    inicioCheckPoint = () => {
        this.props.inicioCheckPoint();        
    }

    checkpoint = () => {
        
        const { objCheckpoint, salvar, form } = this.props;

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const checkpoint = { id: objCheckpoint.id, id_aluno: objCheckpoint.aluno.id, observacao: this.observacaoCheckpoint, id_dom_tipo_checkpoint: this.tipoCheckpoint };
                salvar(checkpoint);        
            }
        });
    }

    changeObservacao = (e) => {
        this.observacaoCheckpoint = e.target.value;
    }

    onChangeTipoCheckpoint = (e) => {
        this.tipoCheckpoint = e.target.value;
    }

    getNomeTurma = (checkpoint) => `${checkpoint.aluno.turma.nome} - ${checkpoint.aluno.turma.dom_serie.nome}`;

    render(){
        const { objCheckpoint, showModal, form: { getFieldDecorator } } = this.props;        

        return(
            objCheckpoint  ? 
                
                <DefaultModal
                    visible={showModal}
                    destroyOnClose={true}
                    title="Checkpoint do Aluno"
                    onCancel={this.cancelar}
                    okText="Realizar o Checkpoint!"
                    onOk={this.checkpoint}                    
                >                    
                    <div id='aluno-checkpoint-modal'>
                        <Row gutter={[16,16]}>
                            <Card>
                                <Col key={0} xs={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} className='col-avatar'>
                                    <Avatar shape="square" size={64} icon="user" src={objCheckpoint.aluno.avatar} />
                                </Col>                              
                                <Col style={{ textAlign: 'left' }} key={2} xs={{ span: 0 }} md={{ span: 20 }} lg={{ span: 20 }} xl={{ span: 20 }}>
                                    <Row >
                                        <Col span={24}>
                                            <Typography.Title style={{ fontSize: 16 }} level={4}>{objCheckpoint.aluno.nome}</Typography.Title>
                                        </Col>
                                        <Col span={24}>
                                            <Typography.Text level={4}><Typography.Text strong>Turma: </Typography.Text>{this.getNomeTurma(objCheckpoint)}</Typography.Text>
                                        </Col>
                                        {objCheckpoint.dt_checkpoint &&
                                            <Col span={24}>
                                                <Typography.Text level={4}><Typography.Text strong>Data: </Typography.Text>{Formatacao.dataHoraBR(objCheckpoint.dt_checkpoint)}</Typography.Text>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                <Col style={{ textAlign: 'center' }} key={3} xs={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0 }} xl={{ span: 0 }}>
                                    <Row >
                                        <Col span={24}>
                                            <Typography.Title style={{ fontSize: 16 }} level={4}>{objCheckpoint.aluno.nome}</Typography.Title>
                                        </Col>
                                        <Col span={24}>
                                            <Typography.Text level={4}><Typography.Text strong>Turma: </Typography.Text>{this.getNomeTurma(objCheckpoint)}</Typography.Text>
                                        </Col>
                                        <Col span={24}>
                                            <Typography.Text level={4}><Typography.Text strong>Data: </Typography.Text>{Formatacao.dataHoraBR(objCheckpoint.dt_checkpoint)}</Typography.Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Card>
                        </Row>    
                        <Row gutter={[16, 16]}>                            
                            <Col key={2} xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Form.Item label="Tipo de Checkpoint">
                                    { getFieldDecorator('id_dom_tipo_checkpoint',{
                                        initialValue: objCheckpoint.id_dom_tipo_checkpoint,
                                        rules: [ { required: true, message: 'Selecione o Tipo de Checkpoint.' } ]
                                    })(
                                        <Radio.Group buttonStyle="solid" onChange={this.onChangeTipoCheckpoint}>
                                            <Radio.Button icon='check-circle' value={Dominio.ID_DADOS_DOMINIO.TIPO_CHECKPOINT.POSITIVO}>Positivo</Radio.Button>
                                            <Radio.Button icon='exclamation-circle' value={Dominio.ID_DADOS_DOMINIO.TIPO_CHECKPOINT.ALERTA}>Alerta</Radio.Button>
                                            <Radio.Button icon='solution' value={Dominio.ID_DADOS_DOMINIO.TIPO_CHECKPOINT.OBSERVACAO}>Observação</Radio.Button>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>   
                            <Col key={3} xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>                                    
                                {objCheckpoint.observacao ?
                                    <Form.Item label="Observação">
                                        <Input.TextArea rows={8} onChange={this.changeObservacao} defaultValue={objCheckpoint.observacao} />
                                    </Form.Item>
                                    :
                                    <Collapse>
                                        <Collapse.Panel header="Escreva uma observação">
                                            <Form.Item label="Observação">
                                                <Input.TextArea rows={8} onChange={this.changeObservacao} defaultValue={objCheckpoint.observacao} />
                                            </Form.Item>
                                        </Collapse.Panel>
                                    </Collapse>                                                         
                                }
                            </Col>                                                        
                        </Row>
                    </div>                    
                </DefaultModal>
            : null
        );
    }
}



const ModalCheckPointRedux = Form.create({})(ModalCheckPoint);

export default connect()(ModalCheckPointRedux);
